<template>
  <v-app>
    <v-container fluid class="white">
      <v-row>
        <v-col cols="12" class="d-flex flex-column justify-center">
          <!-- Sección Mis datos -->
          <v-expansion-panels v-model="isExpandedMisDatos" class="mb-0 pa-5">
            <v-expansion-panel value="1" class="bgcards rounded-lg">
              <v-expansion-panel-header>
                <h3 class="text-h7 font-weight-bold">Mis datos</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Nombre</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.staff.name }}
                  </p>
                </div>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Fecha de nacimiento</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.staff.birthdate }}
                  </p>
                </div>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Correo electrónico</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.staff.email }}
                  </p>
                </div>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Rol</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.staff.role }}
                  </p>
                </div>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Número de teléfono</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.staff.phone }}
                  </p>
                </div>
                <div class="mb-2 d-flex justify-between align-center">
                  <div>
                    <p class="ma-0 pa-0">Contraseña</p>
                    <p class="font-weight-bold ma-0 pa-0">
                      {{ "*".repeat(data.staff.password.length) }}
                    </p>
                  </div>
                  <v-btn
                    text
                    class="font-weight-bold primary--text text-capitalize"
                    @click="goto('change-password')"
                  >
                    Actualizar
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Sección Datos de la cadena -->
          <v-expansion-panels v-model="isExpandedDatosCadena" class="mb-0 pa-5">
            <v-expansion-panel value="2" class="bgcards">
              <v-expansion-panel-header>
                <div class="d-flex justify-between align-center w-100">
                  <h3 class="text-h7 font-weight-bold">Datos de la cadena</h3>
                  <v-btn
                    v-if="isDatosCadenaExpanded"
                    text
                    class="font-weight-bold primary--text text-capitalize"
                    @click="goto('change-chain-data')"
                  >
                    Editar
                  </v-btn>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Nombre</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.chain.name }}
                  </p>
                </div>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Giro</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.chain.turn }}
                  </p>
                </div>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Dirección</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.chain.address }}
                  </p>
                </div>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Número de teléfono</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.chain.phone }}
                  </p>
                </div>
                <div class="mb-2">
                  <p class="ma-0 pa-0">¿Es sucursal?</p>
                  <p class="font-weight-bold ma-0 pa-0">
                    {{ data.chain.branch ? "Si" : "No" }}
                  </p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Sección Plan PAT -->
          <v-expansion-panels v-model="isExpandedPlanPAT" class="mb-0 pa-5">
            <v-expansion-panel value="3" class="bgcards">
              <v-expansion-panel-header>
                <h3 class="text-h7 font-weight-bold">Plan PAT</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mb-2">
                  <p class="ma-0 pa-0">Nota: Aquí va lo de esta sección</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="px-4">
            <v-btn
              rounded
              block
              outlined
              color="primary"
              class="py-6 border-3 mx-auto"
              style="border-width: 3px"
              @click="openPatAssistance"
            >
              <v-icon left>mdi-help-circle-outline</v-icon>
              Asistencia PAT
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { useUserStore } from "@/stores/userStore";

export default {
  name: "configuration",
  data() {
    return {
      isExpandedMisDatos: [],
      isExpandedDatosCadena: [],
      isExpandedPlanPAT: [],
      data: {
        staff: {
          name: "Juan Manuel Patlani",
          birthdate: "16/02/1989",
          email: "juanmanuel@gmail.com",
          role: "Administrador",
          phone: "55 5555 5555",
          password: "contraseña",
        },
        chain: {
          name: "La Divina",
          turn: "Farmacia",
          address:
            "Calle Inventada 123, Colonia Ficticia, Ciudad Imaginaria, CP 45678, País Desconocido.",
          phone: "55 5555 5555",
          branch: true,
        },
      },
    };
  },
  computed: {
    isDatosCadenaExpanded() {
      return !this.isExpandedDatosCadena;
    },
  },
  methods: {
    goto(name) {
      this.$router.replace({ name: name });
    },
    goBack() {
      this.$router.go(-1);
    },
    openPatAssistance() {},
  },
  async mounted(){
    const userStore = useUserStore();
    await userStore.getUser();
    let user =  userStore.user;
    console.log(user);
    this.data.staff.name = user.UsuarioDetalle.nombre;
    this.data.staff.birthdate = user.UsuarioDetalle.fecha_nacimiento;
    this.data.staff.email = user.UsuarioDetalle.correo_electronico;
    this.data.staff.role = "Administrador";
    this.data.staff.phone = user.telefono;
    this.data.staff.password = "*******";
    this.data.chain.name = user.Negocio.nombre;
    this.data.chain.phone = user.telefono;
    this.data.chain.turn = user.Negocio.Giro.nombre;
    this.data.chain.address = user.Negocio.direccion;
  }
};
</script>

<style scoped></style>
